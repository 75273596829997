import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
import './cybersec.css'

import { StripeProvider } from 'react-stripe-elements';
import { Stripe } from 'stripe.js'
import Checkout from '../components/checkout';

import banner3dmodelling from '../pics/3d-modelling-printing-barcelona-code-school.jpg'

import Contacts from '../components/contactform';

import Instalments from '../components/instalments.js'

import { DayPicker } from 'react-day-picker';

const ThreeDmodelling = () => {

    let [duration, setDuration] = useState(null)

    const [selected, setSelected] = useState('');

    const today = new Date()

    let [courses, setCourses] = useState({
        durationdays: null,
        durationweeks: 2,
        fulldesc1: { twoday: "Weekly classes, 18:15-19:15, , in-person, in English", oneday: "1-day workshop, in-person, in English, Saturday, 9:00-18:00" },
        fulldesc2: "Cost: 249€ per month, paid during the registration",
        fullprice: 199,
        image: "https://barcelonacodeschool.com/files/pics/3d-modelling-printing-barcelona-code-school.jpg",
        priceoptions: [],
        shortdesc: "Learn how to create 3D models and print them.",
        signupprice: 199,
        startdates: [''],
        starttime: "18:15",
        tag: "3Dmodelling",
        title: "3D Modelling and Printing",
    })

    // useEffect(()=>{
    // 	let getData = async () => {
    // 		let data = await axios.get('https://barcelonacodeschool.com/events/jsProg')
    // 		setCourses(data.data)
    // 		console.log(data.data)
    // 	}
    // 	getData()
    // },[])

    let renderCourses = () => {
        return courses.startdates.map((date, i) => (
            <div key={i} className=" bottomSpace flex300" >
                <Link to="/register" state={{ course: courses.title, date: date, cost: courses.signupprice, images: [courses.image] }}>
                    <button style={{ 'marginBottom': '1em' }}>Sign up</button>
                </Link>
                <p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
            </div>
        ))
    }


    return <div className="flexContent"
    >
        <Helmet

            title="3D Modelling and Printing"
            meta={[
                {
                    name: "description",
                    content:
                        "Learn 3D modelling and printing, master Blender and print your creatiions",
                },
                {
                    name: "keywords",
                    content:
                        "3d modelling, 3d printing, blender, 3d design, 3d printing course, 3d modelling course",
                },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1",
                },
                {
                    property: "og:title",
                    content:
                        "3D Modelling and Printing",
                },
                {
                    property: "og:description",
                    content:
                        "Learn 3D modelling and printing, master Blender software and print your creatiions",
                },
                {
                    property: "og:image",
                    content:
                        "https://barcelonacodeschool.com/files/pics/3d-modelling-printing-barcelona-code-school.jpg",
                },
                {
                    property: "og:url",
                    content:
                        "https://barcelonacodeschool.com/files/pics/3d-modelling-printing-barcelona-code-school.jpg",
                },
                { name: "twitter:card", content: "summary_large_image" },
            ]}
            script={[
                { "src": "https://js.stripe.com/v3/" }
            ]}
        />

        <h1 className='transparentContainer flex1200'>3D Modelling and Printing</h1>

        <img
            className='banners flex1200 offsetImage'
            src={banner3dmodelling}
            alt='3D Modelling and Printing course in Barcelona Code School' />


        <h2 className='transparentContainer flex1200'>3D Modelling and Printing: From Blender to Reality</h2>

        <div className='transparentContainer flex450'>
            <h3>Overview</h3>
            <article className='whiteCard'>
                <p>In this hands-on course, you’ll dive into the exciting world of 3D modelling and 3D printing. Whether you are completely new to 3D design or have some experience, this course is designed to take you through the entire process—from digital modelling in Blender to preparing your designs for 3D printing and actually bringing them to life using a 3D printer.</p>

                <p>Our expert instructors will guide you through each step, ensuring you have the skills to transform your ideas into tangible objects. By the end of this course, you will have your own custom-designed and 3D-printed creations.</p>
            </article>
        </div>

        <div className='transparentContainer flex450'>
            <h3>Format</h3>
            <article className='whiteCard'>
                <ul>
                    <li>Weekly classes, 1 hour per week</li>
                    <li>Every Tuesday, 18:15-19:15</li>
                    <li>In-person classes on campus</li>
                    <li>Materials: A laptop with Blender installed. We provide access to our 3D printer for in-class use.</li>
                    <li>Cost: 249€ (includes all materials and 3D printing supplies)</li>
                </ul>
            </article>
        </div>




        <article className='transparentContainer flex225'>
            <h2>Tuition</h2>
            <div className='whiteCard'>
                <h2><span className='strikethrough'>249€ per month</span> Early bird special: 199€ per month</h2>
                <p>You first payment done during the registration will cover the first month of classes. Afterwords you can continue as you go or stop at any time after a month end.</p>
                <div className='flexContent'>
                    {renderCourses()}
                </div>

                <div className="bottomSpace flex300">
                    <DayPicker
                        mode="single"
                        selected={selected}
                        onSelect={setSelected}
                        // footer={footer}
                        weekStartsOn={1}
                        fromYear={today.getFullYear()}
                        // defaultMonth={new Date(today.setDate(today.getDate() + 14))}
                        defaultMonth={new Date('12/1/2024')}
                        disabled={day => day < new Date('12/1/2024') || day.getDate() !== 1} // Disable all days except the 1st of each month

                    />
                    {selected ? <Link
                        to="/register"
                        state={{
                            course: '3D Modelling and Printing',
                            date: selected && `${selected.toLocaleString("default", {
                                month: "long",
                            })} ${selected.getDate()}, ${selected.getFullYear()}`,
                            cost: 199,
                            images: ['https://barcelonacodeschool.com/files/pics/3d-modelling-printing-barcelona-code-school.jpg'],
                        }}
                    >
                        <button style={{ marginBottom: "1em" }}>Sign up</button> </Link> : <p className='highlighter'>Please choose a starting month when you want to begin</p>}
                </div>

            </div>
        </article >

        <div className='transparentContainer flex450'>
            <h3>Why taking this course?</h3>
            <div className='whiteCard'>
                <ul>
                    <li>Hands-On Learning: You won’t just learn theory—you’ll actively create 3D models and prints in each session.</li>
                    <li>Small Class Size: Get personalized attention from instructors and collaborate with peers in an intimate learning environment.</li>
                    <li>Real-World Skills: Master skills that can be applied to a variety of fields, from product design to engineering and art.</li>
                    <li>Walk Away with Your Own Creations: By the end of the course, you’ll have multiple 3D-printed objects to take home.</li>
                </ul>
            </div>
        </div>

        <article className="transparentContainer flex450">
            <h2>What you'll learn</h2>
            <div className='whiteCard'>
                <section className='cyberSecTools'>
                    <ul>
                        <li>Introduction to Blender: Learn the essentials of Blender, one of the most powerful open-source 3D design tools.</li>
                        <li>3D Modelling Techniques: Master various modelling methods such as sculpting, extrusion, and subdivision, and learn how to create complex, high-quality 3D models.</li>
                        <li>Preparing Models for 3D Printing: Understand the technical requirements for 3D printing, including proper scale, file formats, and geometry cleanup.</li>
                        <li>Slicing Software: Get introduced to slicing software that converts your 3D model into printable layers. Learn how to adjust settings like print speed, infill density, and supports.</li>
                        <li>Using the 3D Printer: Gain hands-on experience with a real 3D printer, from loading filament to troubleshooting common issues.</li>
                        <li>Finishing Techniques: Learn how to clean up your prints and apply post-processing techniques like sanding, painting, and polishing to perfect your final product.</li>
                    </ul>
                </section>
            </div>
        </article>






        <article className="transparentContainer flex450">
            <h2>Who is this course for</h2>
            <div className='whiteCard'>
                <p>This course is ideal for anyone interested in learning 3D modelling and printing, from complete beginners to more advanced users who want to refine their skills. Whether you’re an aspiring designer, engineer, artist, or hobbyist, this course will provide you with the skills to create real-world objects using cutting-edge technology.</p>
            </div>
        </article>


        <div className='flex450'>
            <Contacts
                title={"Inquiry about the course"}
                subject={"3D Modelling and Printing"}
            />
        </div>



    </div >

}

export default ThreeDmodelling
